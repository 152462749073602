/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import { Link } from 'gatsby';
import { NotificationContext } from '../../components/notification/Notification';
import Authenticator from "../../services/Authenticator";

import './Wallet.scss';

const Wallet = ({ userData }) => {
    const { notify } = useContext(NotificationContext);
    const [isRevealed, setIsRevealed] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [walletAddress, setWalletAddress] = useState(userData?.walletAddress);
    const handleReveal = () => {
        setIsRevealed(!isRevealed);
    };

    useEffect(() => {
        if (!userData) {
            return;
        }
        
        setWalletAddress(userData?.walletAddress);
    }, [userData]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleEditWalletAddress = (event) => {
        setWalletAddress(event.target.value)
    };

    const handleCancel = () => {
        setIsEditing(false);
        setIsRevealed(false);
    };

    const walletDOM = useMemo(() => {
        return <Col xs={12} md={6} className='walletWrapperCol mt-4'>
            <div className='walletHeader'>
                <div className='circle'></div>
                <div className='circle'></div>
                <div className='circle'></div>
            </div>
            <div className='walletWrapper d-flex flex-column justify-content-between'>
                <div>
                    <p>Wallet Address</p>
                    {!isEditing && <p className={`walletAddress ${isRevealed ? 'revealed' : ''}`}>{walletAddress}</p>}
                    {isEditing && <textarea
                        value={walletAddress}
                        onChange={handleEditWalletAddress}
                    />}
                </div>

                <div className=''>
                    {!isEditing && <Link to='' onClick={handleReveal} className='reveal d-block'>{isRevealed ? 'Hide' : 'Reveal'}</Link>}
                    {isEditing && <Link to='' onClick={handleCancel} className='reveal d-block'>Cancel</Link>}
                    {!isEditing && <Button onClick={handleEdit} className='editButton mt-3'>Edit</Button>}
                    {isEditing && <Button onClick={(event) => {(async() => await handleSubmit(event))()}} className='editButton mt-3'>Save</Button>}
                </div>
            </div>
        </Col>
    }, [isRevealed, isEditing, walletAddress]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await Authenticator.updateAttributes({
                walletAddress: walletAddress
            })
            notify({
                message: 'We have successfully updated your data!',
                type: 'success'
            });
            setIsEditing(false);
            setIsRevealed(false);
        } catch (error) {
            notify({
                message: 'Something went wrong. Please try again later.',
                type: 'critical'
            })
        }
    }
    
    const headlineDOM = useMemo(() => {
        return <Col xs={12} className='charities'>
            <h2 className='h6 uppercase sectionHeader'>Deposit Information</h2>
            <h3 className='h5'>Your Wallet Address</h3>
        </Col>
    }, []);

    return <>
        {headlineDOM}
        {walletDOM}
    </>;
}

export default Wallet;